.m-dialog {
  --max-dialog-width: 44rem;

  max-width: var(--max-dialog-width);
  z-index: 9999;

  /* stylelint-disable declaration-colon-space-after */
  & > .a-box,
  & > .a-box--modal > .a-box {
    display: grid;
    grid-template-rows: repeat(3, auto) 1fr;
    grid-template-columns: minmax(auto, var(--max-dialog-width));
    grid-template-areas:
      'remark'
      'header'
      'divider'
      'content';
    max-height: calc(100vh - 4rem);
    overflow: hidden;
  }

  /* stylelint-enable declaration-colon-space-after */

  &__remark {
    grid-area: remark;
    height: rem(6px);

    &.--info {
      background-color: var(--major-signal-neutral__enabled__fill__default);
    }

    &.--success {
      background-color: var(--major-signal-success__enabled__fill__default);
    }

    &.--warning {
      background-color: var(--major-signal-warning__enabled__fill__default);
    }

    &.--error {
      background-color: var(--major-signal-error__enabled__fill__default);
    }
  }

  &__header {
    grid-area: header;
    display: flex;
    align-items: center;
    height: 3rem;
    padding: 0 2rem;
    overflow: hidden;

    > .a-icon {
      margin-right: 0.5rem;
    }

    .a-button--integrated {
      margin-left: auto;
      margin-right: -2rem;
      display: flex;
    }
  }

  &__title {

    @include text-highlight;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // reset margins of divider to achieve design specification
  .a-divider {
    grid-area: divider;
    margin: 0;
  }

  &__content {
    grid-area: content;
    overflow-y: auto;
    padding: 1.5rem 2rem 0;

    &:first-child {
      padding-top: 2rem;
    }

    > *:last-child {
      margin-bottom: 2rem;
    }
  }

  &__headline {
    margin-bottom: 1rem;

    @include size-xl;
    @include text-highlight;
  }

  &__code {
    margin-top: 1rem;

    @include size-s;
  }

  &__actions {
    margin-top: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 1rem;

    .a-button:nth-last-child(2) {
      margin-left: auto;
    }
  }
}
