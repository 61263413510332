.a-chip {
  background-color: var(--neutral__enabled__fill__default);
  color: var(--neutral__enabled__front__default);
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  height: rem(32px);
  width: auto;
  border-radius: rem(16px);
  cursor: pointer;
  margin-right: rem(16px);

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    background-color: var(--neutral__enabled__fill__hovered);
    color: var(--neutral__enabled__front__hovered);
  }

  &:active {
    background-color: var(--neutral__enabled__fill__pressed);
    color: var(--neutral__enabled__front__pressed);
  }

  &.-disabled {
    background-color: var(--neutral__disabled__fill__default);
    color: var(--neutral__disabled__front__default);
  }

  &.-selected {
    background-color: var(--major-accent__enabled__fill__default);
    color: var(--major-accent__enabled__front__default);

    &:hover {
      background-color: var(--major-accent__enabled__fill__hovered);
      color: var(--major-accent__enabled__front__hovered);
    }

    &:active {
      background-color: var(--major-accent__enabled__fill__pressed);
      color: var(--major-accent__enabled__front__pressed);
    }

    &.-disabled {
      background-color: var(--major-accent__disabled__fill__default);
      color: var(--major-accent__disabled__front__default);
    }
  }

  &.-dragged {
    background-color: var(--bosch-white);

    @include box-shadow;
  }

  &--fixed {
    width: rem(97px);

    &.-image,
    &.-btnClose {
      width: rem(118px);
    }

    &.-image.-btnClose {
      width: rem(137px);
      justify-content: space-between;
    }

    .fixed-width-image-label-group {
      width: calc(100% - 1.125rem);
      display: flex;
    }
  }

  &__label {
    margin: auto rem(16px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .-btnClose & {
      margin-right: rem(8px);
    }

    .-image & {
      margin-left: rem(8px);
    }
  }

  &__image {
    width: rem(24px);
    min-width: rem(24px);
    height: rem(24px);
    margin: auto 0 auto rem(4px);
    border-radius: rem(12px);
    background-size: cover;
    background-position: 50% 50%;
  }

  &__close {
    position: relative;
    margin: auto rem(6px);
    width: rem(12px);

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: rem(1px);
      height: rem(14px);
      top: rem(-7px);
      right: rem(10px);
      background: var(--bosch-black);
    }

    &::after {
      transform: rotate(45deg);
    }

    &::before {
      transform: rotate(-45deg);
    }

    svg {
      display: block;
    }
  }
}
