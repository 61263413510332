/// define basic colors
:root {
  // red
  --bosch-red-95: #ffecec;
  --bosch-red-90: #ffd9d9;
  --bosch-red-85: #ffc6c6;
  --bosch-red-80: #ffb2b2;
  --bosch-red-75: #ff9d9d;
  --bosch-red-70: #ff8787;
  --bosch-red-65: #ff6e6f;
  --bosch-red-60: #ff5152;
  --bosch-red-55: #ff2124;
  --bosch-red-50: #ed0007;
  --bosch-red-45: #d50005;
  --bosch-red-40: #be0004;
  --bosch-red-35: #a80003;
  --bosch-red-30: #920002;
  --bosch-red-25: #7d0002;
  --bosch-red-20: #680001;
  --bosch-red-15: #540001;
  --bosch-red-10: #410000;
  --bosch-red-5: #2d0000;
  // purple
  --bosch-purple-95: #f7eef6;
  --bosch-purple-90: #f0dcee;
  --bosch-purple-85: #ebcae8;
  --bosch-purple-80: #e8b6e3;
  --bosch-purple-75: #e5a2df;
  --bosch-purple-70: #e48cdd;
  --bosch-purple-65: #e472db;
  --bosch-purple-60: #e552da;
  --bosch-purple-55: #d543cb;
  --bosch-purple-50: #c535bc;
  --bosch-purple-45: #b12ea9;
  --bosch-purple-40: #9e2896;
  --bosch-purple-35: #8b2284;
  --bosch-purple-30: #791d73;
  --bosch-purple-25: #671761;
  --bosch-purple-20: #551151;
  --bosch-purple-15: #440c41;
  --bosch-purple-10: #340731;
  --bosch-purple-5: #230421;
  // blue
  --bosch-blue-95: #e8f1ff;
  --bosch-blue-90: #d1e4ff;
  --bosch-blue-85: #b8d6ff;
  --bosch-blue-80: #9dc9ff;
  --bosch-blue-75: #7ebdff;
  --bosch-blue-70: #56b0ff;
  --bosch-blue-65: #00a4fd;
  --bosch-blue-60: #0096e8;
  --bosch-blue-55: #0088d4;
  --bosch-blue-50: #007bc0;
  --bosch-blue-45: #006ead;
  --bosch-blue-40: #00629a;
  --bosch-blue-35: #005587;
  --bosch-blue-30: #004975;
  --bosch-blue-25: #003e64;
  --bosch-blue-20: #003253;
  --bosch-blue-15: #002742;
  --bosch-blue-10: #001d33;
  --bosch-blue-5: #001222;
  // turquoise
  --bosch-turquoise-95: #def5f3;
  --bosch-turquoise-90: #b6ede8;
  --bosch-turquoise-85: #a1dfdb;
  --bosch-turquoise-80: #8dd2cd;
  --bosch-turquoise-75: #79c5c0;
  --bosch-turquoise-70: #66b8b2;
  --bosch-turquoise-65: #54aba5;
  --bosch-turquoise-60: #419e98;
  --bosch-turquoise-55: #2e908b;
  --bosch-turquoise-50: #18837e;
  --bosch-turquoise-45: #147671;
  --bosch-turquoise-40: #116864;
  --bosch-turquoise-35: #0e5b57;
  --bosch-turquoise-30: #0a4f4b;
  --bosch-turquoise-25: #07423f;
  --bosch-turquoise-20: #053634;
  --bosch-turquoise-15: #032b28;
  --bosch-turquoise-10: #02201e;
  --bosch-turquoise-5: #011413;
  // green
  --bosch-green-95: #e2f5e7;
  --bosch-green-90: #b8efc9;
  --bosch-green-85: #9be4b3;
  --bosch-green-80: #86d7a2;
  --bosch-green-75: #72ca92;
  --bosch-green-70: #5ebd82;
  --bosch-green-65: #4ab073;
  --bosch-green-60: #37a264;
  --bosch-green-55: #219557;
  --bosch-green-50: #00884a;
  --bosch-green-45: #007a42;
  --bosch-green-40: #006c3a;
  --bosch-green-35: #005f32;
  --bosch-green-30: #00512a;
  --bosch-green-25: #004523;
  --bosch-green-20: #00381b;
  --bosch-green-15: #002c14;
  --bosch-green-10: #00210e;
  --bosch-green-5: #001507;
  // gray
  --bosch-gray-95: #eff1f2;
  --bosch-gray-90: #e0e2e5;
  --bosch-gray-85: #d0d4d8;
  --bosch-gray-80: #c1c7cc;
  --bosch-gray-75: #b2b9c0;
  --bosch-gray-70: #a4abb3;
  --bosch-gray-65: #979ea4;
  --bosch-gray-60: #8a9097;
  --bosch-gray-55: #7d8389;
  --bosch-gray-50: #71767c;
  --bosch-gray-45: #656a6f;
  --bosch-gray-40: #595e62;
  --bosch-gray-35: #4e5256;
  --bosch-gray-30: #43464a;
  --bosch-gray-25: #383b3e;
  --bosch-gray-20: #2e3033;
  --bosch-gray-15: #232628;
  --bosch-gray-10: #1a1c1d;
  --bosch-gray-5: #101112;
  // yellow
  --bosch-yellow-95: #ffefd1;
  --bosch-yellow-90: #ffdf95;
  --bosch-yellow-85: #ffcf00;
  --bosch-yellow-80: #eec100;
  --bosch-yellow-75: #deb300;
  --bosch-yellow-70: #cda600;
  --bosch-yellow-65: #bd9900;
  --bosch-yellow-60: #ad8c00;
  --bosch-yellow-55: #9e7f00;
  --bosch-yellow-50: #8f7300;
  --bosch-yellow-45: #806700;
  --bosch-yellow-40: #725b00;
  --bosch-yellow-35: #644f00;
  --bosch-yellow-30: #564400;
  --bosch-yellow-25: #493900;
  --bosch-yellow-20: #3c2e00;
  --bosch-yellow-15: #2f2400;
  --bosch-yellow-10: #231a00;
  --bosch-yellow-5: #171000;
  // basic
  --bosch-white: #ffffff;
  --bosch-black: #000000;
}
