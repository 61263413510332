.a-page-indicator {
  display: flex;
  align-items: center;

  &__container {
    display: flex;
    flex-wrap: nowrap;
  }

  &.-disabled {
    pointer-events: none;
  }

  &__indicator {
    width: 0.5rem;
    height: 0.5rem;
    margin: 0.5rem;
    background-color: var(--small__enabled__fill__default);
    border-radius: 0.25rem;
    cursor: pointer;

    &:hover {
      background-color: var(--small__enabled__fill__hovered);
    }

    &:active {
      background-color: var(--small__enabled__fill__pressed);
    }

    &.-selected {
      width: 0.75rem;
      height: 0.75rem;
      margin: 0.375rem;
      background-color: var(--major-accent__enabled__fill__default);
      border-radius: 0.375rem;

      .-disabled & {
        background-color: var(--major-accent__disabled__fill__default);
      }
    }

    .-disabled & {
      background-color: var(--small__disabled__fill__default);
    }
  }

  &__caret {
    height: 1.5rem;
    width: 1.5rem;

    &::after {

      @include uiIconForComponents();

      color: var(--integrated__enabled__front__default);
      position: relative;
      cursor: pointer;
      content: '';
    }

    &:first-child {
      margin-right: 0.5rem;
    }

    &:last-child {
      margin-left: 0.5rem;
    }

    &:hover::after {
      color: var(--integrated__enabled__front__hovered);
    }

    &:active::after {
      color: var(--integrated__enabled__front__pressed);
    }

    &.-left::after {
      content: var(--ui-ic-left);
    }

    &.-right::after {
      content: var(--ui-ic-right);
    }


    &.-end {
      &::after {
        cursor: auto;
        color: var(--integrated__disabled__front__default);
      }
    }
  }
}

.a-page-indicator--numbered {
  .a-page-indicator__indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    min-width: 2rem;
    width: auto;
    border-radius: 1rem;
    padding: 0 0.5rem;
    margin: 0 0.25rem;
    background-color: var(--plain__enabled__fill__default);

    &:not([data-index]) {
      pointer-events: none;
    }

    &:hover {
      background-color: var(--major-accent__enabled__fill__hovered);
      color: var(--major-accent__enabled__front__hovered);
    }

    &:active {
      background-color: var(--major-accent__enabled__fill__pressed);
      color: var(--major-accent__enabled__front__pressed);
    }

    span {
      user-select: none;
    }

    &.-selected {
      background-color: var(--major-accent__enabled__fill__default);
      color: var(--major-accent__enabled__front__default);
    }
  }

  &.-disabled .a-page-indicator {
    &__caret {
      &::after {
        cursor: auto;
        color: var(--integrated__disabled__front__default);
      }
    }

    &__indicator {
      color: var(--plain__disabled__front__default);

      &.-selected {
        background-color: var(--major-accent__disabled__fill__default);
        color: var(--major-accent__disabled__front__default);
      }
    }
  }
}
