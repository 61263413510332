%ul {
  margin: 0;
  margin-bottom: 1.5rem;
}

%li {
  list-style: none;
  margin-bottom: rem(16px);
  padding-left: rem(24px);
  position: relative;

  &::before {
    left: 0;
    position: absolute;
  }

  &:first-of-type {
    counter-reset: item;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

%ul-li {
  background-color: var(--plain__enabled__front__default);
  /* stylelint-disable-next-line a11y/content-property-no-static-value */
  content: "";
  height: rem(8px);
  top: rem(9.2px);
  width: rem(8px);
}

%ol-li {
  counter-increment: item;
  /* stylelint-disable-next-line a11y/content-property-no-static-value */
  &::before {
    content: counter(item) ".";
    font-weight: bold;
  }
}

ul {
  @extend %ul;
}

li {
  @extend %li;
}

ul > li::before {
  @extend %ul-li;
}

ol > li {
  @extend %ol-li;
}

// component specific selectors start here

.a-list {
  @extend %ul;

  li {
    @extend %li;
  }

  &--dot {
    li::before {
      @extend %ul-li;
    }
  }

  &--num {
    li {
      @extend %ol-li;
    }
  }

  &--check {
    li::before {
      background: none;
      border-left: rem(2px) solid var(--plain__enabled__front__default);
      border-bottom: rem(2px) solid var(--plain__enabled__front__default);
      content: "";
      display: inline-block;
      height: rem(8px);
      left: 0;
      position: absolute;
      top: rem(2px);
      transform-origin: bottom left;
      transform: translateX(rem(5px)) translateY(rem(8px)) rotate(-45deg);
      width: rem(15px);
    }
  }
}
