.a-pin {
  svg {
    cursor: pointer;

    &:hover {
      path:nth-of-type(1) {
        fill: var(--bosch-blue-30);
      }
    }

    &:active {
      path:nth-of-type(1) {
        fill: var(--bosch-blue-20);
      }
    }
  }

  &.-disabled {
    pointer-events: none;

    svg path:nth-of-type(1) {
      fill: var(--bosch-gray-80);
      stroke: var(--bosch-gray-95);

      @include background-secondary {
        fill: var(--bosch-gray-70);
      }

      @include background-tertiary {
        fill: var(--bosch-gray-80);
      }
    }
  }

  &--cluster {
    align-items: center;
    background-color: var(--bosch-blue-40);
    border: 1px solid var(--bosch-white);
    border-radius: 50%;
    color: var(--bosch-white);
    cursor: pointer;
    display: flex;
    height: rem(48px);
    justify-content: center;
    width: rem(48px);

    &:hover {
      background-color: var(--bosch-blue-30);
    }

    &:active {
      background-color: var(--bosch-blue-20);
    }

    &.-disabled {
      background-color: var(--bosch-gray-80);
      border-color: var(--bosch-gray-95);
      pointer-events: none;

      @include background-secondary {
        background-color: var(--bosch-gray-70);
      }

      @include background-tertiary {
        background-color: var(--bosch-gray-80);
      }
    }
  }
}
