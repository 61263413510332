$indeterminate_width: 0.5rem;

@mixin dynamic-bar-animation-mixin {

  @keyframes dynamic-bar-animation {
    from {
      transform: translateX(-$indeterminate_width);
    }

    to {
      transform: translateX(100%);
    }
  }
}

.a-progress-indicator {
  height: 0.5rem;
  width: 10rem;
  margin: 1.25rem 0;
  background-color: var(--neutral__enabled__fill__default);
  overflow: hidden;
  transform: translateZ(0);

  &__inner-bar {
    position: relative;
    height: inherit;
    background-color: var(--major-accent__enabled__fill__default);

    .a-progress-indicator.-determinate & {
      width: 0;
    }

    .a-progress-indicator.-indeterminate & {
      width: $indeterminate_width;
    }
  }

  &__anim-bar {
    width: inherit;
    height: inherit;
    position: absolute;

    @include dynamic-bar-animation-mixin;

    animation: dynamic-bar-animation 2s infinite linear;
  }
}
