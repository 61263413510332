/* stylelint-disable no-descending-specificity */
/* stylelint-disable a11y/content-property-no-static-value */

@mixin thumb-base {
  margin-top: rem(-11.5px);
  background-color: var(--major-accent__enabled__fill__default);
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  position: relative;

  &:hover {
    background-color: var(--major-accent__enabled__fill__hovered);
  }

  &:active {
    background-color: var(--major-accent__enabled__fill__pressed);
  }
}

.a-slider {
  height: rem(48px);
  width: auto;

  div {
    display: flex;
    position: relative;
    width: 100%;
    flex: 1 1 auto;
    margin: 0.5rem 0.75rem;
  }

  display: flex;
  align-items: center;

  input {
    background: transparent;
    height: 1.5rem;
    width: 100%;
    -webkit-appearance: none;
    outline: 0;
    position: relative;

    &::before {
      background: var(--small__enabled__fill__default);
      position: absolute;
      content: " ";
      left: 0;
      right: 0;
      height: 0.125rem;
      top: 0.6875rem; // (thumbHeight - trackHeight) / 2 --> (1.5 - 0.125) / 2
    }

    &::-webkit-slider-thumb {
      @include thumb-base;

      -webkit-appearance: none;
    }

    &::-webkit-slider-runnable-track {
      height: 0.125rem;
    }

    &::-moz-range-thumb {
      @include thumb-base;

      border: 0;
    }

    &::-moz-range-track {
      background: var(--small__enabled__fill__default);
      height: 0.13rem;
    }

    &:disabled {
      &::-webkit-slider-thumb {
        background-color: var(--major-accent__disabled__fill__default);
      }

      &::-moz-range-thumb {
        background-color: var(--major-accent__disabled__fill__default);
      }
    }
  }

  .a-tooltip {
    text-align: center;
    position: absolute;
    bottom: 2.25rem;
    white-space: nowrap;
    visibility: hidden;
  }

  label {
    font-size: rem(16px);
    flex: 0 1 auto;
  }

  &.a-slider--labels-on-top {
    flex-wrap: wrap;

    // the labels should not shrink below 50%, but can grow as much as they want
    > label {
      order: 1;
      flex: 1 0 50%;
    }

    // the div containing the input should not shrink below 100%, and should be
    // the third element, visually
    // remove the margins around the input so that it aligns with the label's edges
    > div,
    > input {
      order: 3;
      flex: 1 0 100%;
      margin-left: 0;
      margin-right: 0;
    }

    // the label after the input container should be the second element (visually),
    // and the text should right-align
    > div + label,
    > input + label {
      order: 2;
      text-align: right;
    }

    > input {
      margin: rem(8px) 0;
    }
  }
}
