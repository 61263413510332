// Font Classes' Mixins

@mixin text-highlight {
  font-weight: 700;
}

@mixin text-regular {
  font-weight: 400;
}

@mixin text-quote {
  font-weight: 400;
  font-style: italic;
}

@mixin list-element {
  font-weight: 400;
  margin-bottom: rem(16px);
}

@mixin label {
  font-weight: 400;
}

// Size Modifier Mixins

@mixin size-6xl {
  font-size: rem(80px);
  line-height: 1.15;
}

@mixin size-5xl {
  font-size: rem(64px);
  line-height: 1.2;
}

@mixin size-4xl {
  font-size: rem(48px);
  line-height: 1.25;
}

@mixin size-3xl {
  font-size: rem(40px);
  line-height: 1.3;
}

@mixin size-2xl {
  font-size: rem(32px);
  line-height: 1.35;
}

@mixin size-xl {
  font-size: rem(24px);
  line-height: 1.4;
}

@mixin size-l {
  font-size: rem(20px);
  line-height: 1.5;
}

@mixin size-m {
  font-size: rem(16px);
  line-height: 1.5;
}

@mixin size-s {
  font-size: rem(12px);
  line-height: 1.5;
}

// Font Classes' Definitions

.highlight {

  @include text-highlight;
}

.text {

  @include text-regular;
}

.quote {

  @include text-quote;
}

.list-element {

  @include list-element;
}

.label {

  @include label;
}

// Sizes' Definition

.-size-6xl {

  @include size-6xl;
}

.-size-5xl {

  @include size-5xl;
}

.-size-4xl {

  @include size-4xl;
}

.-size-3xl {

  @include size-3xl;
}

.-size-2xl {

  @include size-2xl;
}

.-size-xl {

  @include size-xl;
}

.-size-l {

  @include size-l;
}

.-size-m {

  @include size-m;
}

.-size-s {

  @include size-s;
}

// HTML-Tag Standards

h1 {

  @include text-highlight;
  @include size-4xl;
}

h2 {

  @include text-highlight;
  @include size-3xl;
}

h3 {

  @include text-highlight;
  @include size-2xl;
}

h4 {

  @include text-highlight;
  @include size-xl;
}

h5 {

  @include text-highlight;
  @include size-l;
}

p {

  @include text-regular;
  @include size-m;
}

li {

  @include list-element;
  @include size-m;
}

button {

  @include label;
  @include size-m;
}

figcaption {

  @include text-regular;
  @include size-s;
}

label {

  @include label;
  @include size-s;
}
