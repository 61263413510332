/* stylelint-disable no-descending-specificity */
.a-toggle {
  align-items: center;
  display: flex;

  &__label {
    font-size: rem(16px);
    color: var(--plain__enabled__front__default);


    &.-left {
      margin-right: rem(8px);
    }

    &.-right {
      margin-left: rem(8px);
    }
  }


  /* stylelint-disable-next-line selector-no-qualifying-type */
  input[type='checkbox'] {
    left: -9999px;
    position: absolute;
    visibility: hidden;

    /* stylelint-disable-next-line selector-no-qualifying-type */
    &:checked + .a-toggle__box {
      &::before {
        background-color: var(--major-accent__enabled__fill__default);
      }

      &::after {
        background-color: var(--major-accent__enabled__front__default);
        left: rem(30px);
      }
    }
  }

  &__box {
    cursor: pointer;
    position: relative;
    top: rem(-12px);
    width: rem(48px);

    &::before {
      background-color: var(--small__enabled__fill__default);
      border-radius: rem(12px);
      content: '';
      height: rem(24px);
      position: absolute;
      width: 100%;
    }

    &::after {
      background-color: var(--small__enabled__front__default);
      border-radius: rem(10px);
      content: '';
      height: rem(12px);
      left: rem(6px);
      position: absolute;
      top: rem(6px);
      transition: left 0.2s ease-out, background-color 0.1s ease-out;
      width: rem(12px);
    }
  }

  &:hover {
    .a-toggle__box::before {
      background-color: var(--small__enabled__fill__hovered);
    }
    /* stylelint-disable-next-line selector-no-qualifying-type */
    input[type='checkbox']:checked + .a-toggle__box::before {
      background-color: var(--major-accent__enabled__fill__hovered);
    }
  }

  &:active {
    .a-toggle__box::before {
      background-color: var(--small__enabled__fill__pressed);
    }

    /* stylelint-disable-next-line selector-no-qualifying-type */
    input[type='checkbox']:checked + .a-toggle__box::before {
      background-color: var(--major-accent__enabled__fill__pressed);
    }
  }

  &.-disabled {
    pointer-events: none;

    .a-toggle__box {
      pointer-events: none;

      &::before {
        background-color: var(--small__disabled__fill__default);
      }

      &::after {
        background-color: var(--small__disabled__front__default);
      }
    }

    .a-toggle__label {
      color: var(--plain__disabled__front__default);
    }

    /* stylelint-disable-next-line selector-no-qualifying-type */
    input[type='checkbox']:checked + .a-toggle__box {
      &::before {
        background-color: var(--major-accent__disabled__fill__default);
      }

      &::after {
        background-color: var(--major-accent__disabled__front__default);
      }
    }
  }
}
