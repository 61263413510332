.m-step-indicator {
  &__steps {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0;
  }

  &__step {
    display: grid;
    grid-template-rows: max-content auto;
    row-gap: 0.25rem;
    flex-grow: 1;
    flex-basis: 0;
    list-style: none;
    margin: 0;
    padding: 0;

    // reset sane default of li-tags
    &::before {
      content: unset;
    }

    // line to node before
    &:not(:first-child)::before {
      content: "";
      height: 1px;
      background-color: var(--small__enabled__fill__default);
      width: calc(100% + 1rem);
      top: 1rem;
      left: calc(-50% - 1rem);
    }

    .m-step-indicator.-small &::before {
      top: 0.5rem;
    }

    // line to node before - active state
    &.-active::before {
      background-color: var(--major-accent__enabled__fill__default);
    }
  }

  &__node {
    z-index: 1;
    display: grid;
    place-items: center;
    align-self: start;
    justify-self: center;
    height: 2rem;
    width: 2rem;
    background-color: var(--neutral__disabled__fill__default);
    color: var(--neutral__disabled__front__default);
    border-radius: 50%;

    .m-step-indicator.-small & {
      height: 1rem;
      width: 1rem;
    }

    .m-step-indicator__step.-active & {
      background-color: var(--major-accent__enabled__fill__default);
      color: var(--major-accent__enabled__front__default);
    }

    .a-icon {
      color: currentColor;
    }
  }

  &__label {
    align-self: start;
    justify-self: center;
    text-align: center;
    word-break: break-word;
    hyphens: auto;
    color: var(--plain__disabled__front__default);

    .m-step-indicator__step.-active & {
      color: var(--plain__enabled__front__default);
    }
  }
}
