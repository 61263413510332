.a-divider {
  background-color: var(--small__enabled__fill__default);
  border: 0;
  height: rem(1px);
  margin: rem(8px) 0;

  &.-within-text {
    margin: rem(32px) 0;
  }

  &--vertical {
    min-width: 1px;
    height: auto;
    margin: 0 rem(16px);
  }
}
